import cx from 'classnames';
import { FC } from 'react';

const LoadIndicator: FC<{ ratio?: number; className?: string }> = ({
  ratio = 4,
  className = '',
}) => (
  <div className={className}>
    <div className={cx('zimbro-spinner')} data-uk-spinner={`ratio: ${ratio}`} />
  </div>
);

export default LoadIndicator;
