import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Token } from '@zimbro-app/enums';
import api from '#api';
import { Ledger, PostRatesReply } from 'api/types';

type LedgerContext = {
  ledger: Ledger;
  rates: PostRatesReply;
  isLoading: boolean;
  error: Error | undefined;
};

const LedgerContext = createContext<LedgerContext>({
  isLoading: false,
  error: undefined,
  ledger: [],
  rates: { list: [], tokens: {} },
});

export const useLedgerContext = () => useContext(LedgerContext);

export const LedgerContextProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [ledger, setLedger] = useState<Ledger>([]);
  const [rates, setRates] = useState<PostRatesReply>({ list: [], tokens: {} });

  useEffect(() => {
    const frequency = 60_000;
    let timeout = setTimeout(fetchTx, frequency);
    fetchTx();
    return () => clearTimeout(timeout);

    async function fetchTx() {
      try {
        setIsLoading(true);
        const [txs, rates] = await Promise.all([
          api.post.transactions({
            pageSize: 10,
            orderBy: 'timestamp',
            order: 'desc',
          }),
          api.post.rates({ quote: Token.Usd }),
        ]);
        setLedger(txs);
        setRates(rates);
        timeout = setTimeout(fetchTx, frequency);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const value = { ledger, rates, isLoading, error };

  return (
    <LedgerContext.Provider value={value}>{children}</LedgerContext.Provider>
  );
};
