import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '#api';
import { Vendor } from 'api/types';
import { Token } from '@zimbro-app/enums';

type PaymentFormContext = {
  isLoading: boolean;
  error: Error | undefined;
  vendors: Vendor[];
  vendor?: Vendor;
  submit: () => void;
  total: number;
  ttl: number;
  changeTotal: (x: string) => void;
  changeTtl: (x: string) => void;
  setVendor: (vendor?: Vendor) => void;
};

const PaymentFormContext = createContext<PaymentFormContext>({
  isLoading: false,
  error: undefined,
  vendors: [],
  total: 0,
  ttl: 0,
  submit() {},
  changeTotal() {},
  changeTtl() {},
  setVendor() {},
});

export const usePaymentFormContext = () => useContext(PaymentFormContext);

export const PaymentFormContextProvider: FC<
  PropsWithChildren<{ onSuccess: (paymentId: string) => void }>
> = ({ children, onSuccess }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const [vendor, setVendor] = useState<Vendor | undefined>();
  const [total, setTotal] = useState<number>(100);
  const [ttl, setTtl] = useState<number>(600);

  const changeTotal = (x: string) => x && setTotal(parseFloat(x));
  const changeTtl = (x: string) => x && setTtl(parseInt(x));

  useEffect(() => {
    if (isLoading || error || (vendors && vendors.length)) {
      return;
    }
    setIsLoading(true);
    (async () => {
      try {
        const data = await api.get.vendors();
        if (!data || !data.length) {
          throw new Error('No vendors found');
        }
        setVendors(data);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isLoading, error, vendors]);

  const submit = async () => {
    if (!vendor || !total || !ttl) {
      console.error('Missing required data', { vendor, total, ttl });
      return;
    }
    let signingKey: string;
    try {
      const { vendorId } = vendor;
      const v = await api.get.vendorId({ vendorId });
      if (!v.apiSecret) {
        throw new Error('Failed to get vendor key');
      }
      signingKey = v.apiSecret;
    } catch (error) {
      setError(error as Error);
      return;
    }
    try {
      setIsLoading(true);
      const orderId = self.crypto.randomUUID();
      const request = {
        vendor: vendor.vendorId,
        orderId,
        total,
        ttl,
        token: Token.Usd,
        onSuccess: `${vendor.origin}/order/${orderId}/success`,
        onCancel: `${vendor.origin}/order/${orderId}/cancel`,
        onReturn: `${vendor.origin}/order/${orderId}`,
      };

      const result = await api.post.payment(request, { signingKey });
      onSuccess(result.paymentId);
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    vendors,
    vendor,
    setVendor,
    isLoading,
    error,
    submit,
    total,
    ttl,
    changeTotal,
    changeTtl,
  };

  return (
    <PaymentFormContext.Provider value={value}>
      {children}
    </PaymentFormContext.Provider>
  );
};
