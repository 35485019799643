import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Token } from '@zimbro-app/enums';
import { Balance } from 'api/types';
import { SafeNumber } from '@zimbro-app/util';
import api from '#api';

type BalanceContext = {
  isLoading: boolean;
  error: Error | undefined;
  balance: Balance | undefined;
};

const BalanceContext = createContext<BalanceContext>({
  isLoading: false,
  error: undefined,
  balance: {
    quote: Token.Usd,
    updatedAt: new Date(0),
    totalBalance: SafeNumber(0, 2),
    tokenBalance: [],
  },
});

export const useBalanceContext = () => useContext(BalanceContext);

export const BalanceContextProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [balance, setBalance] = useState<Balance>();

  useEffect(() => {
    const frequency = 60_000;
    let timeout = setTimeout(getBalance, frequency);
    getBalance();
    return () => clearTimeout(timeout);

    async function getBalance() {
      setError(undefined);
      setIsLoading(true);
      try {
        const data = await api.post.balance({ quote: Token.Usd });
        setBalance(data);
        timeout = setTimeout(getBalance, frequency);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     subscribing ||
  //     !account ||
  //     !account.id ||
  //     balance.isLoading ||
  //     balance.error ||
  //     doesListenBalance
  //   ) {
  //     return () => {};
  //   }
  //   console.log('Resubscribing');
  //   setSubscribing(true);
  //   let next: Promise<ApiReply<BalanceReply>>;
  //   let cancel: () => void;
  //   (async () => {
  //     try {
  //       ({ next, cancel } = await api.ws.balance({ quote }));
  //       setDoesListenBalance(true);
  //     } catch (e) {
  //       setBalance({ isLoading: false, error: e as Error });
  //       return;
  //     } finally {
  //       setSubscribing(false);
  //     }
  //     try {
  //       while (true) {
  //         const { success, error, data } = await next;
  //         if (!success || error) {
  //           setBalance({
  //             isLoading: false,
  //             error: new Error(error as string),
  //             data: undefined,
  //           });
  //         } else {
  //           setBalance({
  //             isLoading: false,
  //             error: undefined,
  //             data: {
  //               ...data,
  //               updatedAt: new Date(data.updatedAt),
  //               totalBalance: SafeNumber.fromJson(data.totalBalance),
  //               tokenBalance: data.tokenBalance.map(b => ({
  //                 ...b,
  //                 updatedAt: new Date(b.updatedAt),
  //                 rateUpdatedAt: new Date(b.rateUpdatedAt),
  //                 balance: SafeNumber.fromJson(b.balance),
  //                 tokenRate: SafeNumber.fromJson(b.tokenRate),
  //               })),
  //             },
  //           });
  //         }
  //       }
  //     } catch (e) {
  //       setDoesListenBalance(false);
  //       if (e !== Promised.CANCEL) {
  //         setBalance({ isLoading: false, data: undefined, error: e as Error });
  //       }
  //     }
  //   })();

  //   return () => {
  //     if (typeof cancel === 'function') {
  //       cancel();
  //     }
  //   };
  // }, [
  //   subscribing,
  //   account && account.id,
  //   balance.isLoading,
  //   balance.error,
  //   doesListenBalance,
  // ]);

  const value = { balance, isLoading, error };

  return (
    <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>
  );
};
