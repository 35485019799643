import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseError } from '@zimbro-app/util';
import api from '#api';

export type Account = {
  id: string;
  name: string;
  email: string;
  type: 'personal' | 'business';
};

type AppContext = {
  account?: Account;
  setAccount: (account: Account) => void;
  signOut: () => void;
  setRoute: (route: string) => void;
};

const AppContext = createContext<AppContext>({
  account: undefined,
  setAccount: () => {},
  signOut: () => {},
  setRoute: () => {},
});

class AppContextError extends BaseError {}

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const [account, setAccount] = useState<Account | undefined>();

  const { pathname } = useLocation();
  const [route, setRoute] = useState<string>(pathname);

  useEffect(() => navigate(route, { replace: true }), [route]);
  useEffect(() => {
    // void (async () => {
    //   try {
    //     const pong = await api.get.pingBackend();
    //     console.info('Successfully received pong from backend', pong);
    //   } catch (error) {
    //     console.error('Backend pong error', error);
    //   }
    // })();

    if (account) {
      setRoute('/app');
    } else if (!/^\/payment/.test(pathname)) {
      setRoute('/');
    } else {
      // void (async () => {
      //   try {
      //     const pong = await api.get.pingBackend();
      //     console.info('Successfully received pong from backend', pong);
      //   } catch (error) {
      //     console.error('Backend pong error', error);
      //   }
      //   try {
      //     const { next, cancel } = await api.ws.ping();
      //     try {
      //       const pong = await next;
      //       console.info('Successfully received pong message:', pong);
      //     } catch (error) {
      //       console.error('Failed to receive pong message:', error);
      //     } finally {
      //       try {
      //         cancel();
      //         console.info('Successfully unsubscribed;');
      //       } catch (error) {
      //         console.error('Failed to unsubscribe');
      //       }
      //     }
      //   } catch (error) {
      //     console.error('Failed to subscribe to ping:', error);
      //   }
      // })();
    }
  }, [account, pathname]);

  useEffect(() => {
    if (account && account.id) {
      return;
    }

    const frequency = 60_000;
    let timeout = setTimeout(checkIn, frequency);
    checkIn();

    return () => clearTimeout(timeout);
    async function checkIn() {
      try {
        const account = await api.get.checkIn();

        if (!account || !account.accountId) {
          throw new AppContextError('Invalid server response', {
            account,
          });
        }

        setAccount({
          id: account.accountId,
          name: account.accountName,
          email: account.accountEmail,
          type: account.accountType,
        });
        timeout = setTimeout(checkIn, frequency);
      } catch (e) {
        setAccount(undefined);
      }
    }
  }, [account && account.id]);

  const signOut = async () => {
    setAccount(undefined);
    try {
      await api.get.signOut();
    } catch (e) {
      console.error('Sign out error:', e);
    }
  };

  const value = { account, setAccount, signOut, setRoute };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
