import cx from 'classnames';
import { TokenBalance as TokenBalanceType } from 'api/types';
import { ErrorView, LoadIndicator } from '#components';
import { formatUsd } from '#util';
import { useTranslation } from 'react-i18next';
import { BalanceContextProvider, useBalanceContext } from './Context';
import TokenBalance from './TokenBalance';

const Balance = () => {
  const { t } = useTranslation();
  const { balance, isLoading, error } = useBalanceContext();
  const balances = balance
    ? (balance.tokenBalance || ([] as TokenBalanceType[]))
        .map(b => ({ ...b, usdBalance: b.tokenRate.mul(b.balance) }))
        .sort((a, b) => (a.usdBalance.gte(b.usdBalance) ? -1 : 1))
    : [];

  return (
    <div className={cx('uk-section-small', 'uk-flex')}>
      {isLoading ? (
        <LoadIndicator
          className={cx(
            'uk-width-1-1',
            'uk-flex',
            'uk-flex-center',
            'uk-margin-medium-top',
          )}
          ratio={4}
        />
      ) : error || !balance ? (
        <ErrorView error={error || new Error('Invalid balance')} />
      ) : (
        <div className={cx('uk-width-3-4@m', 'uk-width-1-1@m')}>
          <h2 className='uk-h2'>{formatUsd(balance.totalBalance.format)}</h2>
          <p className={cx('uk-text-meta')}>
            {t('dashboard.balance.total.label', 'Total balance')}
          </p>
          <div>
            {!balances.length ? (
              <h4 className={cx('uk-h4')}>
                {t('dashboard.tokens.no-tokens', 'No Tokens Yet')}
              </h4>
            ) : (
              <ul className='uk-list'>
                {balances.map(b => (
                  <li key={`token-balance-${b.token}-${b.chain}`}>
                    <TokenBalance {...b} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default () => (
  <BalanceContextProvider>
    <Balance />
  </BalanceContextProvider>
);
