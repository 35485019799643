import cx from 'classnames';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ellipsize, formatCountdown, formatTimeRange } from '#util';
import { PaymentStatus } from '@zimbro-app/enums';
import { Payment } from 'api/types';
import { useTimer } from '#hooks';
import { Logo, ErrorView, LoadIndicator } from '#components';
import PaymentMethod from './Method';
import PaymentDetails from './Details';
import { PaymentContextProvider, usePaymentContext } from './Context';

import 'react-circular-progressbar/dist/styles.css';
import './index.less';

const PaymentCard: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(
        'uk-section',
        'uk-content',
        'uk-child-width-1-1@xs',
        'uk-child-width-1-2@s',
        'uk-child-width-1-3@m',
      )}
    >
      <div
        className={cx(
          'uk-card',
          'uk-card-default',
          'uk-card-large',
          'uk-margin-auto',
          'uk-card-body',
          'uk-border-rounded',
        )}
      >
        <nav
          className={cx('uk-navbar-container', 'uk-navbar-transparent')}
          data-uk-navbar
        >
          <div className='uk-navbar-nav uk-navbar-left'>
            <Logo width={44} titled />
          </div>
          <div className={cx('uk-navbar-right')}>
            <NavLink
              to='/signup'
              className='uk-button uk-button-default uk-border-pill'
            >
              {t('Sign-up')}
            </NavLink>
          </div>
        </nav>
        <div>{children}</div>
      </div>
    </div>
  );
};

const Expirable: FC<
  PropsWithChildren<{ at: Date; onReturn: string; disable?: boolean }>
> = ({ children, onReturn, disable, at }) => {
  const now = useTimer(disable ? new Date(0) : at);
  const isExpired = disable ? false : at.getTime() - now.getTime() < 0;

  return (
    <div>
      {isExpired ? (
        <PaymentExpired className={cx('uk-margin-top')} onReturn={onReturn} />
      ) : (
        children
      )}
    </div>
  );
};

const Countdown = ({
  className,
  start,
  end,
  disable,
}: {
  className?: string;
  start: Date;
  end: Date;
  disable?: boolean;
}) => {
  const { t } = useTranslation();
  const now = useTimer(disable ? new Date(0) : end);
  const diff = end.getTime() - now.getTime();
  const value = Math.max(0, diff);
  const counterClass =
    !value || disable
      ? 'zimbro-countdown-off'
      : value < 600_000
        ? 'zimbro-countdown-danger'
        : value < 1_800_000
          ? 'zimbro-countdown-warning'
          : 'zimbro-countdown-default';

  return (
    <div
      className={cx('zimbro-countdown', counterClass, className)}
      data-uk-tooltip={
        value
          ? `${t(
              'payment.expiration.tooltip',
              'Expires',
            )} ${formatTimeRange(end, now)}`
          : t('payment.expired.tooltip', 'Payment expired')
      }
    >
      <CircularProgressbar
        minValue={0}
        maxValue={end.getTime() - start.getTime()}
        value={value}
        strokeWidth={4}
        counterClockwise
        text={disable ? '00:00' : formatCountdown(value)}
      />
    </div>
  );
};

const PaymentExpired: FC<{ onReturn: string; className?: string }> = ({
  onReturn,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx('uk-flex', 'uk-flex-column', 'uk-flex-middle', className)}
    >
      <div>
        <h2 className={cx('uk-text-large', 'uk-text-center', 'uk-text-danger')}>
          {t('payment/method/expired/title', 'Payment expired')}
        </h2>
        <p
          className={cx(
            'uk-text-small',
            'uk-text-danger',
            'uk-margin-medium-bottom',
          )}
        >
          {t(
            'payment/method/expired/notification',
            'Please get back to the store in order to renew the payment.',
          )}
        </p>
      </div>
      <a
        href={onReturn}
        className={cx('uk-button', 'uk-button-secondary', 'uk-border-pill')}
      >
        {t('payment/method/button/back', 'Back to Store')}
      </a>
    </div>
  );
};

const PaymentCanceled: FC<{
  onReturn: string;
  onCancel: string;
  className?: string;
}> = ({ onReturn, onCancel, className }) => {
  const { t } = useTranslation();
  const now = useTimer();
  const COUNT_FROM = 10;
  const [count, setCount] = useState<number>(COUNT_FROM);

  useEffect(() => setCount(Math.max(count - 1, 0)), [now.getTime()]);
  useEffect(() => {
    if (count <= 0) {
      window.location.href = onCancel || onReturn;
    }
  }, [count]);

  return (
    <div
      className={cx('uk-flex', 'uk-flex-column', 'uk-flex-middle', className)}
    >
      <div>
        <h2 className={cx('uk-text-large', 'uk-text-center', 'uk-text-danger')}>
          {t('payment/method/canceled/title', 'Payment canceled')}
        </h2>
        <p
          className={cx(
            'uk-text-small',
            'uk-text-danger',
            'uk-margin-medium-bottom',
          )}
        >
          {t(
            'payment/method/canceled/notification',
            'You will be automatically redirected to the store.',
          )}
        </p>
      </div>
      <div
        className={cx(
          'uk-width-1-5',
          'zimbro-countdown',
          'zimbro-countdown-danger',
        )}
      >
        <CircularProgressbar
          minValue={0}
          maxValue={COUNT_FROM}
          value={count}
          strokeWidth={4}
          counterClockwise
          text={String(count)}
        />
      </div>
      <a
        href={onReturn}
        className={cx(
          'uk-button',
          'uk-margin-medium-top',
          'uk-button-secondary',
          'uk-border-pill',
        )}
      >
        {t('payment/method/button/back', 'Back to Store')}
      </a>
    </div>
  );
};

const PaymentPaid: FC<{
  onReturn: string;
  onSuccess: string;
  className?: string;
}> = ({ onReturn, onSuccess, className }) => {
  const { t } = useTranslation();
  const now = useTimer();
  const COUNT_FROM = 10;
  const [count, setCount] = useState<number>(COUNT_FROM);

  useEffect(() => setCount(Math.max(count - 1, 0)), [now.getTime()]);
  useEffect(() => {
    if (count <= 0) {
      window.location.href = onSuccess || onReturn;
    }
  }, [count]);

  return (
    <div
      className={cx('uk-flex', 'uk-flex-column', 'uk-flex-middle', className)}
    >
      <div>
        <h2
          className={cx('uk-text-large', 'uk-text-center', 'uk-text-success')}
        >
          {t('payment/method/canceled/title', 'Payment accepted!')}
        </h2>
        <p
          className={cx(
            'uk-text-small',
            'uk-text-success',
            'uk-margin-medium-bottom',
          )}
        >
          {t(
            'payment/method/canceled/notification',
            'You will be automatically redirected to the store.',
          )}
        </p>
      </div>
      <div
        className={cx(
          'uk-width-1-5',
          'zimbro-countdown',
          'zimbro-countdown-success',
        )}
      >
        <CircularProgressbar
          minValue={0}
          maxValue={COUNT_FROM}
          value={count}
          strokeWidth={4}
          counterClockwise
          text={String(count)}
        />
      </div>
      <a
        href={onReturn}
        className={cx(
          'uk-button',
          'uk-margin-medium-top',
          'uk-button-secondary',
          'uk-border-pill',
        )}
      >
        {t('payment/method/button/back', 'Back to Store')}
      </a>
    </div>
  );
};

const PaymentDescription = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(
        'uk-flex',
        'uk-margin-medium-top',
        'uk-margin-medium-bottom',
      )}
    >
      <div className={cx('uk-width-1-6')}>
        <Countdown
          disable={[
            PaymentStatus.Canceled,
            PaymentStatus.Paid,
            PaymentStatus.Pending,
          ].includes(payment.paymentStatus)}
          className={cx('uk-width-4-5')}
          start={payment.createdAt}
          end={payment.expiresAt}
        />
      </div>
      <div id='zimbro-payment-heading' className={cx('')}>
        <h1 className={cx('uk-h3', 'uk-margin-remove-bottom')}>
          <a
            href={payment.vendorSite}
            title={payment.vendorSite}
            target='_blank'
          >
            {ellipsize(payment.vendorName, 36)}
          </a>
        </h1>
        <span className={cx('uk-text-muted', 'uk-text-small')}>
          {t('payment/method/order', 'Order')}&nbsp;#
          {ellipsize(payment.orderId, 36)}
        </span>
      </div>
    </div>
  );
};

const PaymentView = () => {
  const { isLoading, payment, error } = usePaymentContext();
  return (
    <PaymentCard>
      {isLoading ? (
        <LoadIndicator
          className={cx('uk-flex', 'uk-flex-center', 'uk-margin-medium-top')}
        />
      ) : error || !payment ? (
        <ErrorView error={error || new Error('Invalid payment')} />
      ) : (
        <>
          <PaymentDescription payment={payment} />
          <Expirable
            at={payment.expiresAt}
            onReturn={payment.onReturn}
            disable={[
              PaymentStatus.Canceled,
              PaymentStatus.Paid,
              PaymentStatus.Pending,
            ].includes(payment.paymentStatus)}
          >
            {payment.paymentStatus === PaymentStatus.Created && (
              <PaymentMethod payment={payment} />
            )}
            {(payment.paymentStatus === PaymentStatus.MethodSet ||
              payment.paymentStatus === PaymentStatus.Pending) && (
              <PaymentDetails payment={payment} />
            )}
            {payment.paymentStatus === PaymentStatus.Canceled && (
              <PaymentCanceled
                onCancel={payment.onCancel}
                onReturn={payment.onReturn}
              />
            )}
            {payment.paymentStatus === PaymentStatus.Paid && (
              <PaymentPaid
                onSuccess={payment.onSuccess}
                onReturn={payment.onReturn}
              />
            )}
          </Expirable>
        </>
      )}
    </PaymentCard>
  );
};

export default () => (
  <PaymentContextProvider>
    <PaymentView />
  </PaymentContextProvider>
);
